<template>
    <div>
        <Breadcrumb></Breadcrumb>
        <div class="header">
            <el-row>
                <div class="form">
                    <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                        <el-form-item label="订单编号">
                            <el-input v-model="searchForm.order_id" placeholder="订单编号" size="small"></el-input>
                        </el-form-item>
                        <el-form-item label="订单状态">
                            <el-select v-model="searchForm.status" clearable placeholder="订单状态" size="small">
                                <el-option label="已预定" value="1"></el-option>
                                <el-option label="已完成" value="2"></el-option>
                                <el-option label="待评价" value="3"></el-option>
                                <el-option label="已取消" value="4"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" size="small" icon="el-icon-search" @click="getOrderList">查询</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </el-row>
            <el-row>
                <el-col :span="22">
                    <el-button type="danger" size="small" @click="delOrder()">批量删除</el-button>
                </el-col>
                <el-col :span="2"></el-col>
            </el-row>
        </div>
        <div class="content">
            <el-table ref="multipleTable" :data="list" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange" :default-sort="{ prop: 'id', order: 'descending' }">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="order_id" label="订单编号"></el-table-column>
                <el-table-column prop="name" label="名称"></el-table-column>
                <el-table-column prop="meal_time" label="干饭时间"></el-table-column>
                <el-table-column prop="status" label="订单状态" :formatter="statusFormatter"></el-table-column>
                <el-table-column prop="chef_ids" label="厨师" :formatter="chefIdsFormatter"></el-table-column>
                <el-table-column label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button type="danger" size="mini" @click="delOrder(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <Pagination :total="total" :perPage="perPage" :currentPage="currentPage" @currentPageChange="currentPageChange"></Pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { getOrderList, delOrder } from '@/utils/servers/order.js';
import { getCheckUser } from '@/utils/servers/user.js';
import Pagination from '@/components/Pagination/Pagination.vue';
export default {
    components: {
        Pagination
    },
    computed: {
        chefIdsFormatter() {
            return (row, column, value) => {
                let ids = value.split(','); // 将逗号分隔的值转换为数组
                let names = ids.map(id => {
                    let arr = this.checkUser.find(val => val.id == id);
                    return arr ? arr.user_name : id; // 如果找到匹配的标签对象，则返回其名称；否则返回原始的值
                });
                return names.join(', '); // 将替换后的值用逗号连接并返回
            };
        },
        statusFormatter() {
            return (row, column, value) => {
                let arr = [
                    { key: 1, name: '已预定' },
                    { key: 2, name: '已完成' },
                    { key: 3, name: '待评价' },
                    { key: 4, name: '已取消' }
                ];
                let item = arr.find(item => item.key === value);
                if (item) {
                    return item.name;
                } else {
                    return value;
                }
            };
        }
    },
    data() {
        return {
            searchForm: {
                order_id: '',
                status: ''
            },
            list: [],
            total: 0,
            perPage: 0,
            currentPage: 0,
            checkIds: [],
            checkUser: []
        };
    },
    created() {
        this.getCheckUser();
        this.getOrderList();
    },
    methods: {
        handleSelectionChange(val) {
            this.checkIds = val ? val.map(item => item.id) : [];
        },
        currentPageChange(val) {
            this.currentPage = val;
            this.getOrderList();
        },
        getOrderList() {
            getOrderList({ searchForm: this.searchForm, perPage: this.perPage, currentPage: this.currentPage }).then(res => {
                var data = res;
                if (data.code == 1) {
                    this.list = data.data.data;
                    this.total = data.data.total;
                    this.perPage = data.data.per_page;
                    this.currentPage = data.data.current_page;
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        getCheckUser() {
            getCheckUser().then(res => {
                var data = res;
                if (data.code == 1) {
                    this.checkUser = data.data.list;
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        delOrder(id) {
            var ids = id ? id : this.checkIds.toString();
            this.$confirm('此操作将永久删除数据，是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delOrder({ ids: ids }).then(res => {
                    var data = res;
                    if (data.code == 1) {
                        this.$message.success(data.msg);

                        if (this.total % this.perPage === 1) {
                            this.currentPage = this.currentPage - 1 > 0 ? this.currentPage - 1 : 1;
                        }

                        this.getOrderList();
                    } else {
                        this.$message.error(data.msg);
                    }
                });
            });
        }
    }
};
</script>

<style lang="less" scoped>
.header {
    background: #fff;
    margin-bottom: 20px;
}
.content {
    .pagination {
        padding: 10px;
    }
}
</style>
