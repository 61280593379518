import request from "@/utils/request";

export function getOrderList(data){
  return request({
    url: '/order/list',
    method: 'post',
    data: data,
  })
}

export function delOrder(data){
  return request({
    url: '/order/del',
    method: 'post',
    data: data,
  })
}